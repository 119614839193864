
import ErrorPage from './errorpage';
import history from './history';
import Header from './header';
import Slider from './carousel';
import Container from './container';
import Dot from './dot';
import Testimony from './testimony';
import Footer from './footer';

export {
  ErrorPage,
  history,
  Header,
  Slider,
  Container,
  Dot,
  Testimony,
  Footer,
};
